<template>
	<div id="Roll">
		<div class="Roll_box">
			<div class="Roll_Nav">
				<p class="" v-if="$store.state.user.anchor == 1" :class="{'active' : Status == '4'}" @click="auditRolls('4')">审核中</p>
				<p class="" :class="{'active' : Status == '1'}" @click="ChangeRolls('1')">进行中</p>
				<p class="" :class="{'active' : Status == '2'}" @click="ChangeRolls('2')">已结束</p>
				<p class="" :class="{'active' : Status == '3'}" @click="ChangeRolls('3')">我参加的</p>
				<p class="" style="background: #ffd322;" @click="ToCreatingRooms">创建房间</p>
			</div>
			<div class="roll-list" >

				<div class="roll-list-item col-lg-4 col-md-6 col-12 px-0"
					 v-for="(item,index) in RoomList" :key="index" @click="toRoom(item.id)">
					<div class="roll-list-item-bg m-auto" style="width: 95%;position: relative">
						<div class="roll-item-top position-relative">
							<img width="100%" src="../../assets/images/public/roll-top.png" alt="">
							<div class="shijian" style="text-align: center;margin-top: -10px">
								<span class="mx-1">ROLL时间:{{item.end_time}}</span>
							</div>
						</div>

						<div class="roll-list-item-con d-flex align-items-center" >
							<div style="margin-right: 10px">
								<img width="50px" height="50px" class="roll-list-item-user" :src="item.user?item.user.avatar:''" alt="">
							</div>
							<div class="">
								<div class="text-overflow" style="font-weight: bold;font-size: 20px">
									{{item.name}}</div>
							</div>

						</div>
						<div class="roll-list-item-bottom">
							<div style="width: 30%" class="roll-list-item-bottom-img"
								 v-for="(value,index) in item.box_records.slice(0,3)" :key="index" >
								<img width="100%" :src="value.cover" alt="">
							</div>
						</div>
						<div class="roll-list-item-con justify-content-between">
							<div class="ml-1">
								<div class="roll-list-item-con-active d-flex align-items-center justify-content-between">
									<div class=" d-flex align-items-center">
										<div>件数：<span class="money-color font-weight-bold">{{item.box_records.length}}</span></div>
										<div>人数：<span class="money-color font-weight-bold">{{item.join_number}}</span></div>
									</div>
									<div class="d-flex align-items-center" style="color: #ECB105">奖池：<span class="d-flex align-items-center money-color font-weight-bold"><img width="20px" src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.award_bean}}</span></div>

								</div>
							</div>
						</div>
						<div class="roll-add" :class="item.status == '1'?'':'roll-add-no'">
							{{item.status == '1'?'进入房间':item.status == '2'?'查看详情':'房间待审核'}}
						</div>

<!--						<div :class="item.status == '1'?'pos-flex-no':'pos-flex'"-->
<!--							 style="background-color: rgba(255,255,255,.3)">-->
<!--							<div :class="item.status == '1'?'pos-flex-no-room':'pos-flex-room'"-->
<!--								 style="">-->
<!--							</div>-->
<!--						</div>-->

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Rooms,
		UserRooms,
	rollPending
	} from '@/network/api.js'
	export default {
		name: 'Roll',
		data() {
			return {
				RoomList: [], //房间列表
				Status: '1', //房间状态
			}
		},
		filters: {
			StyChoice(val) {
				// console.log(val)
				if (val == '1') {
					return 'border-bottom-color:#e6ba1c;'
				} else if (val == '2') {
					return 'border-bottom-color:#d32ce6;'
				} else if (val == '3') {
					return 'border-bottom-color:#834fff;'
				} else if (val == '4') {
					return 'border-bottom-color:#4b69ff;'
				} else {
					return 'border-bottom-color:#bbbbbb;'
				}
			}
		},
		created() {
			this.GetRooms()
		},
		mounted(){
			console.log(this.$store.state.user.anchor)
		},
		methods: {
			// 获取审核列表
			auditRolls(v){
				this.Status = v
				rollPending().then(res =>{
					this.RoomList = res.data.data.data
				})
			},
			//获取房间列表(初始)
			GetRooms() {
				Rooms('1', this.Status).then((res) => {
					// console.log(res.data.data.data)
					this.RoomList = res.data.data.data
				})
			},

			//参加房间记录(我参与的)
			GetUserRooms() {
				UserRooms().then((res) => {
					this.RoomList = res.data.data.data
				})
			},

			//改变房间列表
			ChangeRolls(v) {
				this.Status = v
				console.log(v)
				switch (v) {
					case '1':
						this.GetRooms()
						break;
					case '2':
						this.GetRooms()
						break;
					default:
						this.GetUserRooms()
				}
			},

			//路由跳转房间详情
			toRoom(id){
				// console.log(id)
				this.$router.push({
					path:'/room',
					query:{
						id:id
					}
				})
			},

			ToCreatingRooms(){
				this.$router.push('/CreatingRooms')
			}
		}
	}
</script>

<style lang="scss" scoped>
.roll-list-item-bg{
	background-image: url("../../assets/images/jinkuang.png");
}
	.roll-add{
		width: 100%;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		border-top: 1px solid #232749;
		cursor: pointer;
		&:hover{
			background-image: linear-gradient(90deg, #ECB105, #EC7205);
		}
	}
	.roll-add-no{
		-webkit-filter: grayscale(100%);
	}
	.shijian{
		position: absolute;
		bottom: -10px;
		left: 10%;
		width: 80%;
		height: 40px;
		background-color: #191C34;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 20px;
		color: #7D82B3;

	}
	.roll-bg{

		background-size: 100%;
		background-repeat: no-repeat;
		@media (max-width: 1000px) {
			background-size: cover;
			background-position: center;
		}
	}
	.pos-flex-no{
		display: none!important;
	}
	.roll-list-item:hover .pos-flex-no{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex!important;;
		align-items: center;
		justify-content: center;
	}
	.roll-list-item:hover .pos-flex-no-room{
		width: 175px;
		height: 50px;

		background-size: 100% 100%;
		text-align: center;
		line-height: 50px;
		font-weight: bold;
		cursor: pointer;
		@media (max-width: 1000px) {
			width:50%;
		}
	}
	.pos-flex{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		/*background-image: url("../../assets/images/rooms/bg.png");*/
		/*background-color: #131426;*/
		&-room{
			width: 50%;
			height: 50px;

			background-size: 100% 100%;
			text-align: center;
			line-height: 50px;
			font-weight: bold;
			cursor: pointer;
		}
	}

	.roll-list{
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		&-item{
			&-con{
				display: flex;
				padding: 10px 20px;
				flex-wrap: wrap;
				&-active{
					display: flex;
					margin-top: 5px;
					align-items: center;
					font-size: 16px;
					flex-wrap: wrap;
					width: 3.5rem;
					& div	{
						margin-right: 15px;
					}

				}
				@media (max-width: 1000px) {
					padding: 5px 10px;
				}
				@media (max-width: 768px) {
					/*img{*/
					/*	display: none;*/
					/*}*/
				}

			}
			&-user{
				border-radius: 50%;
				overflow: hidden;
			}
			&-bottom{
				display: flex;
				flex-wrap: wrap;
				padding: 0 30px;
				&-img{
					background-color: rgba(#FC246A,.2);
					border: 1px solid #FC246A;
					background-size: 100% 100%;
					margin: 0 5px;
					& img{
						display: block;
						width: 80%;
						margin: 0 auto;
					}
				}
				@media (max-width: 1000px) {
					padding: 0 10px;
				}
			}
			&-bg{
				overflow: hidden;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-color: #131426;

			}
			width: 33.33%;
			margin-bottom: 20px;
			@media (max-width:1000px) {
				width: 50%;
			}
		}
	}
	.Roll_box {
		padding-top: 40px;
		width: 100%;
		.Roll_Nav {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			p {
				height: 30px;
				padding: 0 26px;
				background: #191C34;
				text-align: center;
				line-height: 30px;
				font-size: 14px;
				color: #fff;
				margin: 0 5px;
				&.active {
					background-image: linear-gradient(90deg, #ECB105, #EC7205);
				}
			}
		}

		.Roll_List {
			width: 1400px;
			// border: 1px solid red;
			box-sizing: border-box;
			display: flex;
			flex-wrap: wrap;
			margin-top: 50px;
			.Roll_item {
				width: 290px;
				background: url(../../assets/images/Roll/ROLL_boxBack.png) no-repeat center;
				background-size: cover;
				border-radius: 16px;
				position: relative;
				padding-top: 50px;
				padding-bottom: 20px;
				margin-bottom: 100px;
				margin-right: 75px;
				cursor: pointer;
				&:nth-child(4n){
					margin-right: 0;
				}
				.Roll_pic {
					position: absolute;
					left: 50%;
					top: -30px;
					width: 70px;
					height: 70px;
					border-radius: 50%;
					overflow: hidden;
					transform: translateX(-50%);
					border: 2px solid #ffba2e;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 130%;
						height: 130%;
					}
				}

				.Roll_tit {
					text-align: center;
					font-size: 14px;
					color: #fff;
				}

				.Roll_Data {
					width: 100%;
					display: flex;
					justify-content: center;
					margin-top: 10px;

					.Roll_data_box {
						width: 30%;
						text-align: center;

						p {
							color: #aaa;
							font-size: 12px;
						}

						span {
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 14px;
							color: #fff;
							font-weight: bold;
							margin-top: 10px;

							img {
								width: 18px;
								margin-right: 1px;
							}
						}
					}
				}

				.Roll_Goods {
					width: 100%;
					height: 70px;
					margin-top: 10px;
					display: flex;
					padding: 0 12px;
					box-sizing: border-box;
					justify-content: space-around;
					flex-wrap: wrap;
					overflow: hidden;

					.Roll_Goods_item {
						width: 30%;
						height: 70px;
						border-bottom: 2px solid transparent;
						box-sizing: border-box;

						.pic {
							width: 90%;
							height: 90%;
							margin: 2px auto 0;
							display: flex;
							justify-content: center;
							align-items: center;
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;

							img {
								max-width: 90%;
								max-width: 90%;
							}
						}
					}
				}

				.Roll_btn {
					width: 100px;
					height: 32px;
					margin: 20px auto;
					border: 2px solid #ffd322;
					border-radius: 20px;
					text-align: center;
					line-height: 32px;
					color: #ffd322;
					font-size: 14px;
				}

				.Roll_time {
					text-align: center;
					color: #fff;
					font-size: 14px;
				}
			}

		}


	}
</style>
